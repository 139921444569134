/**
 * @param allPosts
 * @returns {JSX.Element
 * @constructor
 */
import Layout from "../components/layout/layout";
import Head from "next/head";
import Container from "../components/Container";
import Block from "../components/Block";
import LoginForm from "../components/customers/LoginForm";
import LoginLayout from "../components/customers/LoginLayout";
import {useRouter} from "next/router";
import {useStateMachine} from "little-state-machine";
import {setCart, setUser} from "../lib/actions";
import NavBar from "../components/navbar/NavBar";
import {useEffect} from "react";

export default function Login() {
  const router = useRouter();

  const { actions, state } = useStateMachine({
    setUser, setCart
  });

  useEffect(() => {
    if (state.user) {
      router.replace('/');
    }
  }, [state.user])

  const handleSuccess = (data) => {
    actions.setUser({ user: data });
  }

  return (
    <>
      <Layout>
        <Head>
          <title>LENTO - Login</title>
        </Head>
        <Container>
          <Block>
            <NavBar />
          </Block>
          <LoginLayout>
            <LoginForm handleSuccess={handleSuccess} />
          </LoginLayout>
        </Container>
      </Layout>
    </>
  )
}
