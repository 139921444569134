import {useForm} from "react-hook-form";
import * as shopifyClientApi from "../../lib/api-client-side-shopify";
import {useRouter} from "next/router";
import {classNames} from "../../lib/functions";
import {useState} from "react";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import InputFieldText from "../inputs/InputFieldText";
import Block from "../Block";
import {RiLoader4Fill} from "react-icons/ri";

const fieldObjects = {
  email: {
    field: 'email',
    key: 'email',
    type: 'text',
    placeholder: 'Email',
    registerOptions: {
      required: "Email is required"
    }
  },
  password: {
    field: 'password',
    key: 'password',
    type: 'password',
    placeholder: 'Password',
    registerOptions: {
      required: "Password is required"
    }
  },
}

/**
 * @param handleSuccess
 * @returns {JSX.Element}
 * @constructor
 */
export default function LoginForm({handleSuccess}) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setIsSuccess] = useState(false);
  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);

    shopifyClientApi.loginCustomer(data).then((loginData) => {
      localStorage.setItem('customerAccessToken', loginData.customerAccessToken.accessToken)

      shopifyClientApi.findCustomer(loginData.customerAccessToken.accessToken).then((userData) => {
        setIsSuccess(true);
        handleSuccess(userData);
      })
    }).catch((error) => {
      setError("email", {
        type: "manual",
        message: "No match found for given username/password",
      });
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const LoginView = (
    <div className="w-full md:text-gray-700">
      <div>
        <div className="flex justify-center">
          <h1>LENTO</h1>
        </div>
        <h1 className="mb-4 text-2xl font-bold text-center">
          Login
        </h1>
      </div>

      {errors.email &&
        <div className="bg-red-50 border-t border border-red-500 text-red-700 px-4 py-3 mb-2" role="alert">
          <div className="flex">
            <div className="py-1 px-1 mb-2">
              <AiOutlineExclamationCircle size={40}/>
            </div>
            <div className="flex items-center">
              <p className="text-sm">{errors.email.message}.</p>
            </div>
          </div>
        </div>
      }

      <form className="flex flex-col gap-3 text-gray-700" onSubmit={handleSubmit(onSubmit)}>
        <InputFieldText
          autoFocus={true}
          field={fieldObjects.email.field}
          type={fieldObjects.email.type}
          placeholder={fieldObjects.email.placeholder}
          className="w-full text-sm focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600"
          registerOptions={{...fieldObjects.email.registerOptions}}
          errors={errors[fieldObjects.email.key] ?? null}
          register={register}
        />
        <InputFieldText
          field={fieldObjects.password.field}
          type={fieldObjects.password.type}
          placeholder={fieldObjects.password.placeholder}
          className="w-full text-sm focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600"
          registerOptions={{...fieldObjects.password.registerOptions}}
          errors={errors[fieldObjects.password.key] ?? null}
          register={register}
        />
        <button type="submit" className={classNames(isLoading ? "loading": "", "btn btn-content w-full")}>
          Login
        </button>
      </form>

      <div className="mt-4 text-center">
        <p className="text-sm mb-2">Don't have an account yet?
          <a onClick={() => router.replace('/signup')} className="text-blue-600 hover:underline px-1 cursor-pointer">Sign up.</a>
        </p>
        <p className="text-sm">Unable to login?
          <a onClick={() => router.replace('/forgot-password')} className="text-blue-600 hover:underline px-1 cursor-pointer">Forgot password</a>
        </p>
      </div>
    </div>
  );

  const LoadingView = (
    <Block className="mx-auto px-2 mt-5">
      <div className="w-full flex gap-4 flex-col justify-center items-center">
        <p className="font-mazzard text-center">Login success!</p>
        <p className="font-mazzard text-center">Redirecting you to our landing page...</p>
        <RiLoader4Fill className="icon-spin" size={40} />
      </div>
    </Block>
  );

    return (
      <>
        {!success && LoginView}
        {success && LoadingView}
      </>
  );
}