export default function LoginLayout({ children }) {
  return (
    <div className="flex justify-center pt-[20vh] w-full min-h-screen">
      <div className="px-8 w-full min-w-[20rem] max-w-[40rem]">
        {children}
      </div>
    </div>
  );
}
// <div className="w-full mx-auto bg-white rounded-lg md:shadow-xl lg:min-h-screen ">
// </div>
// {/*<div className="flex md:flex-row min-h-screen">*/}
// {/*<div className="min-h-screen bg-cover md:w-1/2 md:min-h-[50rem] md:relative absolute bg-opacity-60 w-full z-10" style={SideImageStyle} />*/}
// {/*<div className="absolute w-full h-full bg-gray-800 bg-opacity-20 md:w-1/2 z-20"/>*/}
// {/*</div>*/}
