import TextInput from "./TextInput";

export default function InputFieldText({ autoFocus, className, register, field, type, registerOptions, placeholder, errors }) {
  const label = registerOptions?.required ? placeholder + '*': placeholder;

  return (
    <div className={className}>
      <div className="block">
        <label className="font-bold text-sm text-gray-600 uppercase">{label} :</label>
        <TextInput
          autoFocus={autoFocus}
          type={type}
          register={register}
          field={field}
          registerOptions={registerOptions}
        />
      </div>
      {errors && <span className="text-sm text-red-700">{errors.message}</span> }
    </div>
  )
}
