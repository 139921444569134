import {classNames} from "../../lib/functions";

/**
 *
 * @param className
 * @param placeholder
 * @param register
 * @param field
 * @param registerOptions
 * @returns {JSX.Element}
 * @constructor
 */
export default function TextInput({ autoFocus = false, placeholder = "", register, type, field, registerOptions }) {
  return (
    <input
      className={classNames("input-md w-full text-sm border border-gray-400 rounded-md focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600 placeholder-gray-600")}
      autoFocus={autoFocus}
      type={type}
      placeholder={placeholder}
      {...register(field, registerOptions)}
    />
  );
}